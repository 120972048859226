<template>
  <div class="PageBox">
    <div class="header_bg">
      <div style="text-align: right;font-size: 16px;padding: 5px">
        <van-row>
          <van-col span="18" @click="gotoApp" style="text-align: left">
            {{$t('common5[6]')}}
          </van-col>
          <van-col span="6" @click="$router.push('/language')">{{$t('login.text[0]')}}</van-col>
        </van-row>
      </div>
    </div>
    <div class="loginpanel">
      <div class="logo">
        <img :src="`./static/images/logo5.png`"></img>
      </div>
      <div  class="loginpanel2">
        <van-field
                type="text"
                autocomplete="off"
                style="height: 0; width: 0; padding: 0; position: absolute"
        />
        <van-field
                type="password"
                autocomplete="off"
                style="height: 0; width: 0; padding: 0; position: absolute"
        />
        <van-field
                :formatter="formatterUsername"
                :border="false"
                class="input"
                v-model.trim="postData.username"
                left-icon="./static/icon/mail.png"
                :placeholder="$t('login.placeholder[0]')"
                size="large"
                clearable
                autocomplete="off"
        />
        <van-field
                :border="false"
                class="input"
                v-model.trim="postData.password"
                :type="showPass ? 'text' : 'password'"
                left-icon="./static/icon/pwd.png"
                :placeholder="$t('login.placeholder[1]')"
                size="large"
                clearable
                autocomplete="off"
                :right-icon="showPass ? 'eye' : 'closed-eye'"
                @click-right-icon="showPass = !showPass"
        />
        <div style="text-align: right;margin-top: 20px">
          <router-link class="href" to="/resetpwd">{{
            $t('login.default[2]')
            }}</router-link>
        </div>
      <div style="padding: 10px 0px;text-align: center">
        <p class="btn" @click="onSubmit()">{{ $t('login.text[2]') }}</p>
        <div>
          {{$t('login.default[0]') }} |
          <router-link slot="a" to="/register">
            <span style="color: #D7D7D7">{{
            $t('login.default[1]')
              }}</span></router-link>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "Login",
    components: {},
    props: ["username"],
    data() {
      return {
        postData: {
          username: localStorage["UserName"] || "",
          password: localStorage["PassWord"] || "",
          uniqueID: localStorage["phoneId"] || "", //手机唯一标识
        },
        keepUser: localStorage["KeepUser"] == "1" ? true : false,
        isSubmit: false,
        showPass: false,
        language1: localStorage.getItem("Language") == "cn",
      };
    },
    computed: {},
    watch: {},
    created() {
      this.isSubmit = false;
      console.log(this.language1);
    },
    mounted() {
      var query= this.$route.query;
      if (query["username"]){
        this.postData.username = query["username"];
      }
    },
    activated() {},
    destroyed() {},
    methods: {
      onSubmit() {
        if (!this.postData.username) {
          this.$Dialog.Toast(this.$t("login.placeholder[0]"));
          return;
        }
        if (!this.postData.password) {
          this.$Dialog.Toast(this.$t("login.placeholder[1]"));
          return;
        }
        this.isSubmit = true;
        this.$Model.Login(this.postData, (data) => {
          this.isSubmit = false;
          if (data.code==0){
            let idx = data.code_dec;
            let msg = this.$t("login.codes['"+idx+"']");
            this.$toast(msg);
            return;
          }
          var appData = {};
          try {
            var data = localStorage["AppData"];
            if (data) {
              appData = JSON.parse(data);
            }
          } catch (e) {
            // alert('3'+e.message)
            appData = {};
          }
          if (this.keepUser) {
            appData["UserName"] = this.postData.username;
            appData["PassWord"] = this.postData.password;
            localStorage["UserName"] = this.postData.username;
            localStorage["PassWord"] = this.postData.password;
          } else {
            appData["UserName"] = null;
            appData["PassWord"] = null;
            localStorage.removeItem("UserName");
            localStorage.removeItem("PassWord");
          }
          var data = JSON.stringify(appData);
          localStorage["AppData"] = data;
          var ret = this.$Util.writeAppStorage(data);
          this.$router.push("/");
        });
      },
      changeKeepUser() {
        var appData = {};
        try {
          var data = localStorage["AppData"];
          if (data) {
            appData = JSON.parse(data);
          }
        } catch (e) {
          // alert('3'+e.message)
          appData = {};
        }
        if (this.keepUser) {
          appData["KeepUser"] = 1;
          localStorage["KeepUser"] = 1;
        } else {
          appData["KeepUser"] = 0;
          localStorage.removeItem("KeepUser");
        }
        var data = JSON.stringify(appData);
        localStorage["AppData"] = data;
        this.$Util.writeAppStorage(data);
      },
      // 用户名格式化不能输入空格
      formatterUsername(value) {
        return value.replace(/\s*/g, "");
      },
      gotoApp(){
        window.location.href = this.InitData.setting.app_url;
      }
    },
  };
</script>
<style scoped>
  .LoginHeader {
    padding: 0 0;
  }

  .PageBox {
    color: #B5B5B5;
    padding: 0;
    background-color: #13171A;
  }
  .ScrollBox {

  }

  .loginpanel {
    margin-top: 35px;
    height:330px;
    /*background: url('../../public/static/images/bg_login.png') no-repeat center 0;*/
    background-size: cover;
    /*background-color: #191C23;*/
    width: 100%;
    padding-bottom: 60px;
  }

  .logo {
    margin: 50px auto;
    margin-left: 90px;
  }

  .loginpanel img {
    margin: 20px auto;
    text-align: center;
    width: 200px;
  }

  .PageBox a:link {
    color: #635327;
  }

  .header_bg {
    width: 100%;
    z-index: 0;
  }

  .van-cell--borderless.input {
    width: 85%;
    background-color: #191C23;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 25px;
    margin-left: 30px;
    padding: 0 30px 0 13px;
    height: 45px;
  }
  .van-cell >>> .van-field__body {
    height: 100%;
  }
  .van-cell >>> .van-icon-closed-eye {
    font-size: 19px;
    color: #939393;
    margin-top: 5px;
  }
  .van-cell--borderless >>> .van-cell__value--alone {
    height: 100%;
  }

  .van-cell--borderless >>> .van-checkbox__label {
    color: #555 !important;
    font-size: 13px;
    font-family: Alibaba;
    margin-top: -1px;
  }

  .van-cell--borderless >>> .van-icon-success {
    border: 1px solid #ff93a4;
    box-shadow: 0px 0px 0px 0.5px #ff93a4;
    margin-left: 1px;
  }


  .van-cell--borderless >>> .van-icon {
    font-size: 25px !important;
    color: #ccc !important;
    margin-top: 6px;
  }

  .van-cell--borderless.input >>> .van-field__control {
    color: #939393 !important;
    padding-left: 7px;
    height: 100%;
    font-size: 18px;
  }

  .van-cell--borderless.input >>> .van-field__control::-webkit-input-placeholder {
    color: #939393;
    font-size: 19px;
    font-family: Alibaba;
  }

  .van-cell--borderless.input >>> .van-field__control::-moz-placeholder {
    color: #939393;
    font-size: 19px;
    font-family: Alibaba;
  }

  .van-cell--borderless.input >>> .van-field__control::-ms-input-placeholder {
    color: #939393;
    font-size: 18px;
    font-family: Alibaba;
  }

  .van-form {
    padding: 0 35px;
  }

  .van-button--danger {
    max-width: 200px;
    margin: auto;
    font-family: Alibaba;
    font-size: 20px !important;
    font-weight: bold;
    background: linear-gradient(90deg, #fff, #fff);
    color: #4e51bf;
  }

  .goRe a {
    color: #fe889d;
  }

  .van-cell--borderless >>> .van-icon-manager,
  .van-cell--borderless >>> .van-icon-lock {
    color: #ccc !important;
  }

  .van-cell--borderless >>> .van-icon-manager::before,
  .van-cell--borderless >>> .van-icon-lock::before {
    font-size: 27px;
    margin-top: 4px;
  }

  .van-cell--large {
    padding-top: 17px;
    padding-bottom: 8px;
  }

  .Site .van-nav-bar >>> .van-icon {
    color: #333;
  }

  .van-nav-bar__text1 >>> .van-nav-bar__text {
    letter-spacing: 0.09rem;
    color: #000000;
  }

  .PageBox >>> .letter_s {
    letter-spacing: 0.08rem;
  }
  .goRe {
    margin-top: 20px;
    font-family: Alibaba;
    font-size: 15px;
    text-align: left;
    color: #4e51bf;
  }

  .href {
    color: #0076FA;
  }

  .PageBox a:link {
    color: #0076FA;
  }

</style>
